import { createSlice } from '@reduxjs/toolkit';

export const planSlice = createSlice({
    name:'plans',
    initialState:{
        activePlan: 0,
        refValue:null,
    },
    reducers:{
        selectPlan:(state,action)=>{
            state.activePlan = action.payload;

        },
        selectValue:(state,action)=>{
            state.refValue = action.payload;

        },
    }
});




export const { selectPlan } = planSlice.actions;
export const {selectValue} = planSlice.actions;

export default planSlice.reducer;
