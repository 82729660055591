import { Button,} from '@material-ui/core'
import { ArrowForwardIos} from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'
import { useHistory, useParams } from 'react-router'
import {PLAN_PROVIDER, SERVICE_PROVIDER} from './Graphql/Queries'
import {useQuery} from '@apollo/client'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { selectPlan } from './features/planSlice'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import Skeleton from '@material-ui/lab/Skeleton'
import './Service.css'

function Service() {
  
    const {id} = useParams()
    // eslint-disable-next-line no-unused-vars
    const {t} = useTranslation()
    
    const dispatch = useDispatch()
    const history = useHistory()
    const lang = localStorage.getItem("lang")
    // eslint-disable-next-line no-unused-vars
    const {data:data3,loading:loading3,error:error3} = useQuery(SERVICE_PROVIDER,{variables:{id,lang}})
     const planTag = data3?.service_providers[0].tags[0]
     const [tagValue, setTagValue] = useState()
     useEffect(()=>{
      setTagValue(planTag)
     },[planTag])
    // eslint-disable-next-line no-unused-vars
    const { data:data1, loading:loading1, error:error1} = useQuery(PLAN_PROVIDER,{variables:{id,tagValue,lang}})    

   
  if(error3 || error1){
     console.error(error3)
  }
  const openSummary=(plan)=>{  
  dispatch(selectPlan({...plan}))
 history.push('/summary')
}

 
    return (
        <>
        <motion.div
        variants={{
           hidden:{
              opacity:0,
              x:-70,
           },
           
           visible:(i)=>({
              opacity:1,
              x:1,
              transition:{
                ease: [0.17, 0.67, 0.83, 0.67],
                duration:0.15
              }
              
           })
        }}
        
        initial="hidden"
        animate="visible">
        <div className="ooredoo">
        <div className="ooredoo__header">
        <h2>{data3?.service_providers[0].name}</h2>
        <p>{data3?.service_providers[0].meta_data.description}</p>
        </div>
        {loading3 && 
        <div className="ooredo__number">
          <div className="ooredoo__loadingbutton">
          <Skeleton duration={1} animation="wave" width={50} />  
          <Skeleton className="loadingButton1"  duration={1} animation="wave" width={50} />  
          <Skeleton duration={1} animation="wave" width={50} />  
            </div>
          </div>
        }
      <div className="ooredo__number">         
       <div className={lang === 'ar' ? 'ooredoo__buttonARA' : 'ooredoo__button'}>
     {data3?.service_providers[0].tags.map(tag=>(
          <FormControlLabel  control={<Checkbox checkedIcon={<Button className={lang === 'ar' ? 'oored00__inputARA': 'oored00__input'}  variant="contained" color="primary" >{tag}</Button>}  key={tag} icon={<Button className={lang === 'ar' ? 'oored00__inputARA': 'oored00__input'} variant="outlined" color="primary" >{tag}</Button>} checked={tagValue === tag} /> } onClick={() => setTagValue(tag)}/>
       
     ))}
   
       </div>
      </div>
      

    {loading1 &&   
    <>
     <div className="ooredoo__validity">
      <div className="ooredoo__icco">
        <div className="ooredoo__data">
        <Skeleton duration={1} animation="wave" width={180} />     
         </div>
         <div className="oredo_Loadingvoice">
         <Skeleton duration={1} animation="wave" width={180} />
         </div>
     </div>
      </div>
      
      <div className="ooredoo__validity">
      <div className="ooredoo__icco">
        <div className="ooredoo__data">
        <Skeleton duration={1} animation="wave" width={180} />     
         </div>
         <div className="oredo_Loadingvoice">
         <Skeleton duration={1} animation="wave" width={180} />
         </div>
     </div>
      </div>
      <div className="ooredoo__validity">
      <div className="ooredoo__icco">
        <div className="ooredoo__data">
        <Skeleton duration={1} animation="wave" width={180} />     
         </div>
         <div className="oredo_Loadingvoice">
         <Skeleton duration={1} animation="wave" width={180} />
         </div>
     </div>
      </div>
      
      <div className="ooredoo__validity">
      <div className="ooredoo__icco">
        <div className="ooredoo__data">
        <Skeleton duration={1} animation="wave" width={180} />     
         </div>
         <div className="oredo_Loadingvoice">
         <Skeleton duration={1} animation="wave" width={180} />
         </div>
     </div>
      </div>
      <div className="ooredoo__validity">
      <div className="ooredoo__icco">
        <div className="ooredoo__data">
        <Skeleton duration={1} animation="wave" width={180} />     
         </div>
         <div className="oredo_Loadingvoice">
         <Skeleton duration={1} animation="wave" width={180} />
         </div>
     </div>
      </div>
      
      <div className="ooredoo__validity">
      <div className="ooredoo__icco">
        <div className="ooredoo__data">
        <Skeleton duration={1} animation="wave" width={180} />     
         </div>
         <div className="oredo_Loadingvoice">
         <Skeleton duration={1} animation="wave" width={180} />
         </div>
     </div>
      </div>
    </>
    }
         
         {data1?.plans.map(plan=>(
              
    <div className="ooredoo__validity" key={plan?.name} onClick={()=>openSummary(plan)}>
        <div className="ooredoo__icco">
        <div className="ooredoo__data">
         <h2>{plan?.name}</h2>           
         </div>
         <div className="oredo_voiice">
             <h5>{plan.meta_data.description}</h5>
         </div>
     </div>
              
         
      <div className="ooredoo__arro">
          <ArrowForwardIos/>
      </div>
    </div>
            
         ))}
       
         {(!loading1 && !data1?.plans.length) && <h4 className="no__plan">{t("no_plan")}</h4>}
     
    </div>
        </motion.div>
        
     </>
    )
}

export default Service
