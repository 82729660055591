import {gql} from '@apollo/client'

export const USER_MUTATION = gql`
mutation getUser($tokenValue: String!) {
  getUser(arg1: {token: $tokenValue}) {
    data
    status
  }

  }
`
export const PAY_ORDER = gql`
mutation payOrder($ref:String!) {
  payOrder(args: {payment_code:  "thawani", reference: $ref}) {
    data
    status
    type
  }
}
`
export const CREATE__ORDER = gql`
mutation createOrder( $evcode:String!,$ref:String!,  $lang:String!)  {
  createOrder(args: { evcode: $evcode, payment_method_code: "thawani", reference: $ref, code:$lang}) {
    data
    status
  }
}
`